import React, { useState, useEffect, useCallback } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Menu from "./Menu"
import { Redirect, useHistory } from "react-router-dom"
import { Button } from "react-bootstrap"
import { fs, auth } from "../../Config/Config"
import "react-datepicker/dist/react-datepicker.css"
import Icon from "react-icons-kit"
import { eye } from "react-icons-kit/fa/eye"
import ServerSideDataTable from "./ServerSideDataTable"
import moment from "moment"
import { Form, Row, Col } from "react-bootstrap"

function OrderHistory() {
  const history = useHistory()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const columns = [
    {
      field: "date",
      headerName: "Order Date",
      maxWidth: 170,
      flex: 1,
      renderCell: (cellValues) => {
        let formattedDate = ""
        let dateString = cellValues.value
        if (typeof dateString === "string" && dateString.trim() !== "") {
          const datePart =
            dateString.split(" ")[0] +
            " " +
            dateString.split(" ")[1] +
            " " +
            dateString.split(" ")[2]
          const timePart = dateString.split(" ")[3]
          const formattedDatePart = moment(datePart, "MMMM D, YYYY").format(
            "DD/MM/YYYY"
          )
          formattedDate = `${formattedDatePart} ${timePart}`
        } else {
          formattedDate = "-"
        }
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        )
      },
    },
    { field: "orderNo", headerName: "No.", width: 60 },
    {
      field: "payment",
      headerName: "Payment",
      align: "center",
      headerAlign: "center",
      valueFormatter: ({ value }) => {
        if (!value || !value.type) return ""
        return value.type.charAt(0).toUpperCase() + value.type.slice(1)
      },
      maxWidth: 90,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      maxWidth: 90,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div>
            <span
              style={{
                color: cellValues.value === "declined" ? "red" : "green",
              }}
            >
              {cellValues.value.charAt(0).toUpperCase() +
                cellValues.value.slice(1)}
            </span>
          </div>
        )
      },
    },
    { field: "postCode", headerName: "Post", maxWidth: 70, flex: 1 },
    { field: "user", headerName: "Customer", flex: 1 },
    { field: "Telephone", headerName: "Phone", width: 110 },
    {
      field: "view",
      headerName: "View",
      width: 60,
      align: "center",
      renderCell: (cellValues) => {
        return (
          <div>
            <Button
              variant="primary btn-sm"
              onClick={() => {
                handleView(cellValues.id)
              }}
            >
              <Icon icon={eye} size="20" />
            </Button>
          </div>
        )
      },
    },
  ]

  const [search, setSearch] = useState({
    orderNo: 0,
  })
  const handleSearch = (event) => {
    event.preventDefault()
    let number = Number(event.target[0].value)
    const newValue = {
      orderNo: number,
    }
    if (search.orderNo !== number) {
      setSearch(newValue)
      setPage(0)
    }
  }
  const [triggerReload, setTriggerReload] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const fetchOrders = useCallback(async (page, pageSize, search) => {
    let query = fs.collection("orderHistory").orderBy("orderNo", "desc")
    let totalQuery = fs.collection("orderHistory").orderBy("orderNo", "desc")

    if (search.orderNo !== 0) {
      query = query.where("orderNo", "==", search.orderNo)
      totalQuery = totalQuery.where("orderNo", "==", search.orderNo)
    }

    if (page > 0) {
      const lastDocSnapshot = await query.limit(page * pageSize).get()
      const lastDoc = lastDocSnapshot.docs[lastDocSnapshot.docs.length - 1]
      query = query.startAfter(lastDoc)
    }

    const snapshot = await query.limit(pageSize).get()
    const totalDocs = await totalQuery.get()
    const data = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return {
      data,
      total: totalDocs.size,
    }
  }, [])

  const [order, setOrder] = useState()

  function handleView(id) {
    fs.collection("orderHistory")
      .doc(id)
      .get()
      .then((snapshot) => {
        setOrder(snapshot.data())
      })
  }
  useEffect(() => {
    if (order) {
      history.push({
        pathname: "/eachorder",
        state: {
          orders: order,
        },
      })
    }
  }, [order])

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="p-3">
          <h2>Order History</h2>
          <div>
            <div className="search-container">
              <Form onSubmit={handleSearch} className="mb-2">
                <Row className="gy-2 gx-3">
                  <Col xs={12} sm={6}>
                    <div className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <Form.Control
                          type="number"
                          placeholder="Search Order No."
                          size="sm" // Smaller size
                          className="form-control-sm"
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="ml-2 btn-sm "
                      >
                        Search
                      </Button>
                    </div>
                    <span className="text-hint pb-0 mb-0">
                      Matches from start, case-sensitive
                    </span>
                  </Col>
                </Row>
              </Form>
            </div>
            <div
              style={{
                backgroundColor: "#FFFF",
              }}
            >
              <ServerSideDataTable
                columns={columns}
                fetchData={fetchOrders}
                search={search}
                setPage={setPage}
                setPageSize={setPageSize}
                page={page}
                pageSize={pageSize}
                triggerReload={triggerReload}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default OrderHistory
