import React, { useState, useRef, useEffect } from "react"
import { storage, fs, auth } from "../../Config/Config"
import Header from "./Header"
import Menu from "./Menu"
import Footer from "./Footer"
import { Icon } from "react-icons-kit"
import { plus } from "react-icons-kit/feather/plus"
import { minus } from "react-icons-kit/feather/minus"
import { useLocation, useHistory } from "react-router-dom"
import { Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export default function EditProducts() {
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const location = useLocation()
  const history = useHistory()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [price, setPrice] = useState("")
  const [image, setImage] = useState(null)

  const [imageError, setImageError] = useState("")

  const [successMsg, setSuccessMsg] = useState("")
  const [uploadError, setUploadError] = useState("")
  const [UpdateImg, setUpdateImg] = useState(false)
  const [categoryUID, setCategoryUID] = useState("")
  const categoryRef = useRef()

  const [loading, setLoading] = useState(true)
  const [loadingMsg, setLoadingMsg] = useState("")

  const types = ["image/jpg", "image/jpeg", "image/png", "image/PNG"]

  const [inputFields, setInputFields] = useState([
    {
      title: "",
      menu: [
        {
          menuName: "",
          price: 0,
        },
      ],
    },
  ])
  //console.log(location.state.uid);
  const uidProducts = location.state.uid
  useEffect(() => {
    if (!uidProducts) {
      history.push("/manageproducts")
    }
    fs.collection("Products")
      .doc(uidProducts)
      .get()
      .then((snapshot) => {
        // //console.log(user);
        // //console.log(user.uid);
        setTitle(snapshot.data().title)
        setDescription(snapshot.data().description)
        setPrice(snapshot.data().price)
        setCategoryUID(snapshot.data().categoryID)
        setImage(snapshot.data().img)
        setInputFields(snapshot.data().option)
      })
  }, [])
  //console.log(image);

  const titleRef = useRef([])

  const handleChangeTitle = (index, event) => {
    // //console.log(index, event.target.value);
    const values = [...inputFields]
    values[index]["title"] = event.target.value
    setInputFields(values)
    //console.log(inputFields);
  }

  const handleChangeMenu = (index, index_child, event) => {
    // //console.log(index, event.target.value);
    const values = [...inputFields]
    values[index]["menu"][index_child][event.target.name] = event.target.value
    setInputFields(values)
    //console.log(inputFields);
  }

  function GetCategoryFromFirebase() {
    const getCategoryFromFirebase = []
    const [categoryFs, setCategoryFs] = useState()
    useEffect(async () => {
      const snapshot = await fs.collection("category").get()
      snapshot.docs.map((doc) => {
        getCategoryFromFirebase.push({ ...doc.data(), key: doc.id })
      })
      setCategoryFs(getCategoryFromFirebase)
      setLoading(false)
    }, [])
    return categoryFs
  }

  const categoryFs = GetCategoryFromFirebase()

  const handleAddTitle = () => {
    setInputFields([
      ...inputFields,
      {
        title: "",
        menu: [
          {
            menuName: "",
            price: 0,
          },
        ],
      },
    ])
  }

  const handleRemoveTitle = (index) => {
    const values = [...inputFields]
    values.splice(index, 1)
    setInputFields(values)
  }

  const handleAddMenu = (index) => {
    // //console.log(inputFields[index].menu[0]);
    // //console.log(titleRef.current[index].value);
    if (inputFields.length == 1) {
      setInputFields([
        {
          title: titleRef.current[index].value,
          menu: [
            ...inputFields[index].menu,
            {
              menuName: "",
              price: 0,
            },
          ],
        },
      ])
    } else {
      const values = [...inputFields]
      // //console.log(values);
      values.push({
        title: titleRef.current[index].value,
        menu: [
          ...inputFields[index].menu,
          {
            menuName: "",
            price: 0,
          },
        ],
      })
      values.splice(index, 1)
      setInputFields(values)
    }
  }

  const handleRemoveMenu = (index, index_child) => {
    const values = inputFields[index].menu
    // //console.log(values);
    values.splice(index_child, 1)
    if (inputFields.length == 1) {
      // console.log("if")
      setInputFields([
        {
          title: titleRef.current[index].value,
          menu: values,
        },
      ])
    } else {
      // console.log(inputFields.length)
      const all = [
        ...inputFields,
        {
          title: titleRef.current[index].value,
          menu: values,
        },
      ]
      setInputFields(all)
      all.splice(index, 1)
      setInputFields(all)
    }
  }

  const handleProductImg = (e) => {
    let selectedFile = e.target.files[0]
    if (selectedFile) {
      if (selectedFile && types.includes(selectedFile.type)) {
        setImage(selectedFile)
        setImageError("")
        setUpdateImg(true)
      } else {
        setImage(null)
        setUpdateImg(false)
        setImageError("please select a valid image file type (png or jpg)")
      }
    } else {
    }
  }

  const handleUpdateProducts = (e) => {
    e.preventDefault()
    setLoadingMsg("Loading...")

    let checkTitle = true
    let checkMenuName = true

    inputFields.forEach((inputField) => {
      if (inputField.title === "") {
        checkTitle = false
      }
      if (inputField.menu.length === 0) {
        checkMenuName = false
      } else {
        inputField.menu.forEach((menu) => {
          if (menu.menuName === "") {
            checkMenuName = false
          }
        })
      }
    })

    if (!checkTitle || !checkMenuName) {
      setLoadingMsg("")
      return setUploadError("Please fill in the missing Add-on Title/Menu Name")
    }
    window.scrollTo(0, 0)
    // console.log(inputFields)
    if (UpdateImg) {
      const uploadTask = storage.ref(`product-images/${image.name}`).put(image)
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          //console.log(progress);
        },
        (error) => setUploadError(error.message),
        () => {
          storage
            .ref("product-images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              fs.collection("Products")
                .doc(uidProducts)
                .update({
                  title,
                  description,
                  categoryID: categoryUID,
                  price: Number(price),
                  img: url,
                  option: inputFields,
                })
                .then(() => {
                  setSuccessMsg("Product update successfully")
                  setTitle("")
                  setDescription("")
                  setPrice("")
                  document.getElementById("file").value = ""
                  setImageError("")
                  setUploadError("")
                  setImage(null)
                  setInputFields([
                    {
                      title: "",
                      menu: [
                        {
                          menuName: "",
                          price: 0,
                        },
                      ],
                    },
                  ])
                  setTimeout(() => {
                    setSuccessMsg("")
                    history.push("/manageproducts")
                  }, 2000)
                })
                .catch((error) => setUploadError(error.message))
            })
        }
      )
    } else {
      fs.collection("Products")
        .doc(uidProducts)
        .update({
          title,
          description,
          categoryID: categoryUID,
          price: Number(price),
          option: inputFields,
        })
        .then(() => {
          setSuccessMsg("Product update successfully")
          setTitle("")
          setDescription("")
          setPrice("")
          document.getElementById("file").value = ""
          setImageError("")
          setUploadError("")
          setImage(null)
          setTimeout(() => {
            setSuccessMsg("")
            history.push("/manageproducts")
          }, 2000)
        })
        .catch((error) => setUploadError(error.message))
    }
  }

  function handleChangeCategory(e) {
    let values = e.target.value
    setCategoryUID(values)
  }

  let getCategory
  if (categoryFs) {
    getCategory = categoryFs.map((category, index) => {
      return (
        <option key={index} value={category.key} ref={categoryRef}>
          {category.category}
        </option>
      )
    })
  } else {
    getCategory = () => {
      return <></>
    }
  }
  if (loading) {
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div
            style={{
              display: "flex",
              backgroundColor: "#f4f6f9",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} className="spinner" size="8x" />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="p-3">
          <h2>Edit Products</h2>
          <hr></hr>
          {loadingMsg ? <Alert variant="secondary">{loadingMsg}</Alert> : ""}
          {successMsg && (
            <>
              <div className="success-msg">{successMsg}</div>
              <br></br>
            </>
          )}
          <form
            autoComplete="off"
            className="form-group"
            onSubmit={handleUpdateProducts}
          >
            <label>Product Title</label>
            <input
              type="text"
              className="form-control mb-2"
              required
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            ></input>
            <label>Product Description</label>
            <input
              type="text"
              className="form-control mb-2"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            ></input>
            <label>Product Price</label>
            <input
              type="number"
              className="form-control mb-2"
              required
              onChange={(e) => setPrice(e.target.value)}
              value={price}
            ></input>
            <label>Product Category</label>
            <select
              className="form-control mb-2"
              required
              onChange={(e) => {
                handleChangeCategory(e)
              }}
              defaultValue={categoryUID}
            >
              {/* {console.log(category)}
              {console.log(categoryUID)} */}
              {loading ? (
                <>
                  <option></option>
                </>
              ) : (
                <>{getCategory}</>
              )}
            </select>
            <div className="d-grid col">
              <label>Upload Product Image</label>
              {UpdateImg ? (
                <></>
              ) : (
                <img src={image} height="150px" className="mb-2" />
              )}
            </div>
            <input
              type="file"
              id="file"
              className="form-control mb-2"
              onChange={handleProductImg}
            ></input>
            <label>Add Add-on</label>
            {inputFields.map((titleField, index) => (
              <div key={index}>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  value={titleField.title}
                  placeholder="Add-on Title"
                  ref={(el) => (titleRef.current[index] = el)}
                  onChange={(event) => handleChangeTitle(index, event)}
                />
                {titleField.menu.map((menuField, index_child) => (
                  <div key={index_child} className="d-flex">
                    <input
                      className="form-control"
                      type="text"
                      name="menuName"
                      placeholder="Menu Name"
                      value={menuField.menuName}
                      onChange={(event) =>
                        handleChangeMenu(index, index_child, event)
                      }
                      autoFocus
                    />
                    <input
                      className="form-control"
                      type="number"
                      name="price"
                      value={menuField.price}
                      onChange={(event) =>
                        handleChangeMenu(index, index_child, event)
                      }
                    />
                    <Icon
                      icon={plus}
                      size={20}
                      onClick={() => handleAddMenu(index)}
                    />
                    <Icon
                      icon={minus}
                      size={20}
                      onClick={() => handleRemoveMenu(index, index_child)}
                    />
                  </div>
                ))}
                <Icon icon={plus} size={20} onClick={() => handleAddTitle()} />
                <Icon
                  icon={minus}
                  size={20}
                  onClick={() => handleRemoveTitle(index)}
                />
                <hr />
              </div>
            ))}
            <p style={{ color: "red" }}>
              If the Add-on Title box have no Menu box left. Please delete that
              Title box as well.
            </p>
            {imageError && (
              <>
                <br></br>
                <div className="error-msg">{imageError}</div>
              </>
            )}
            <div className="d-grid justify-content-center">
              <button type="submit" className="btn btn-success btn-md mb-4">
                Update
              </button>
            </div>
          </form>
          {uploadError && (
            <>
              <br></br>
              <div className="error-msg">{uploadError}</div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </div>
  )
}
