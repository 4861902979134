import React from "react";

function NotFound() {
  return (
    <div>
      <h2>404 Not found</h2>
      <p>Not found Page</p>
    </div>
  );
}

export default NotFound;
