import React, { useEffect, useRef, useState } from "react"
import { Form, Card, Button, Container, Alert } from "react-bootstrap"
import { auth } from "../Config/Config"
import { Link, useHistory } from "react-router-dom"
import logo from "../Images/thaisunlogo_circle.png"

function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        history.push("/")
      }
    })
  }, [])

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setError("")
      setLoading(true)

      await auth
        .signInWithEmailAndPassword(
          emailRef.current.value,
          passwordRef.current.value
        )
        .then(async (currentUser) => {
          if (!currentUser.user.emailVerified) {
            setError("Please check your email to verify, then try again.")
            await auth.signOut()
          } else {
            setSuccess("Log in successfully.")
            history.push("/")
          }
        })
        .catch((error) => {
          console.log(error)
          const errorCode = JSON.parse(error.message)
          setError(errorCode.message)
        })
    } catch {
      setError("Failed to sign in")
    }
    setLoading(false)
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <img src={logo} width="50px" alt="thaisun-logo" />
          <span className="login-logo-font"> Thaisun</span>
        </div>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-2">Log In</h2>
            {success ? <Alert variant="success">{success}</Alert> : ""}
            {error ? <Alert variant="danger">{error}</Alert> : ""}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>

              <Form.Group id="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <div className="queryProfilebtn">
                <Button
                  variant="danger"
                  disabled={loading}
                  className="w-100"
                  type="submit"
                >
                  Log In
                </Button>
              </div>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link style={{ color: "#e80532" }} to="/forgot-password">
                Forgot Password?
              </Link>
            </div>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Need an account?{" "}
          <Link style={{ color: "#e80532" }} to="/signup">
            Sign Up
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default Login
