import React, { useState, useEffect, useRef } from "react"

import { auth, fs } from "../Config/Config"

import { useHistory, useLocation } from "react-router-dom"
import { Button, Modal, Form, Alert } from "react-bootstrap"

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { format } from "date-fns"
import Navbar1 from "./Navbar1"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

function Checkout() {
  let fee = 0
  const [fromCart, setFromCart] = useState(null)
  const local = useLocation()
  const history = useHistory()
  const textInstructionRef = useRef()
  const [isAdmin, setIsAdmin] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [house, setHouse] = useState("")
  const [address, setAddress] = useState("")
  const [town, setTown] = useState("")
  // const [county, setCounty] = useState("")
  const [postCode, setPostCode] = useState("")
  const [tel, setTel] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [uidUser, setUidUser] = useState("")

  const [error, setError] = useState("")
  const [message, setMessage] = useState("")

  const [couponType, setCouponType] = useState(false)

  const [pickupState, setPickupState] = useState(
    localStorage.getItem("Pickup") === "true"
  )
  const [buttonDisable, setButtonDisable] = useState(true)

  const addressRef = useRef()
  const houseRef = useRef()
  // const countyRef = useRef()
  const postCodeRef = useRef()

  const [deliveryChange, setDeliveryChange] = useState(false)

  const setupPickupState = () => {
    if (
      localStorage.getItem("Pickup") === null ||
      localStorage.getItem("Delivery") === null
    ) {
      console.log("Pickup not set")
      localStorage.setItem("Pickup", true)
      localStorage.setItem("Delivery", false)
      fee = 0
      setPickupState(true)
    } else if (localStorage.getItem("Delivery") === "true") {
      console.log("Delivery set")
      setPickupState(false)
      fee = 2
    } else if (localStorage.getItem("Pickup") === "true") {
      console.log("Pickup set")
      setPickupState(true)
      fee = 0
    }
    setFromCart((prevState) => ({
      ...prevState,
      Fee: fee,
      Total: Number(
        Number(prevState.Subtotal) - Number(prevState.Discount) + fee
      ).toFixed(2),
    }))
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            setFirstName(snapshot.data().FirstName)
            setLastName(snapshot.data().LastName)
            setEmail(snapshot.data().Email)
            setHouse(snapshot.data().House)
            setAddress(snapshot.data().Address)
            setTown(snapshot.data().Town)
            // setCounty(snapshot.data().County)
            setPostCode(snapshot.data().PostCode)
            setTel(snapshot.data().Telephone)
            setUidUser(user.uid)
            setIsAdmin(snapshot.data().isAdmin)
            Coupons = snapshot.data().Coupons
            setButtonDisable(false)
          })
      } else {
        history.push("/login")
      }
    })
  }, [])

  const [townTemp, setTownTemp] = useState(town)
  const [postCodeTemp, setPostCodeTemp] = useState(postCode)

  let Coupons = []

  function GetCouponsUser() {
    const [coupon, setCoupon] = useState([])

    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          fs.collection("users")
            .doc(user.uid)
            .get()
            .then((snapshot) => {
              setCoupon(snapshot.data().Coupons)
            })
        } else {
          setCoupon([])
        }
      })
    }, [])
    return coupon
  }

  Coupons = GetCouponsUser()

  function checkLS(post) {
    let code = post
    const searchTerm = "LS"
    const indexLS = code.indexOf(searchTerm)
    code = code.slice(indexLS, indexLS + 4) //LS12 ex: L is index 0 so 2 is index 4 (+4)
    // console.log(code);
    return code
  }

  const miles = 3 //default 3 miles
  useEffect(() => {
    if (localStorage.getItem("Delivery") == "true") {
      if (
        checkLS(postCode).toUpperCase() != "LS12" &&
        checkLS(postCode).toUpperCase() != "LS13" &&
        checkLS(postCode).toUpperCase() != "LS28"
      ) {
        setButtonDisable(true)
      } else {
        setButtonDisable(false)
      }

      // setFee(2);
      console.log("set fee 2")
      fee = 2
      /*
      if (town === 'Calvery') {
        // setFee(2);
        if (checkLS(postCode).toUpperCase() === 'LS12') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        } else if (checkLS(postCode).toUpperCase() === 'LS13') {
          let distance = Number(3 - miles)
          fee = Number(distance + fee_temp)
        }
        // else if (checkLS(postCode).toUpperCase() === "LS18") {
        //   let distance = Number(4 - miles);
        //   fee = Number(distance + fee_temp);
        // }
        else if (checkLS(postCode).toUpperCase() === 'LS28') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        }
      } else if (town === 'Bramley') {
        // setFee(2);
        if (checkLS(postCode).toUpperCase() === 'LS12') {
          let distance = Number(16 - miles)
          fee = Number(distance + fee_temp)
        } else if (checkLS(postCode).toUpperCase() === 'LS13') {
          let distance = Number(16 - miles)
          fee = Number(distance + fee_temp)
        }
        // else if (checkLS(postCode).toUpperCase() === "LS18") {
        //   let distance = Number(16 - miles);
        //   fee = Number(distance + fee_temp);
        // }
        else if (checkLS(postCode).toUpperCase() === 'LS28') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        }
      } else if (town === 'Armley') {
        // setFee(2);
        if (checkLS(postCode).toUpperCase() === 'LS12') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
          // setFee(20);
        } else if (checkLS(postCode).toUpperCase() === 'LS13') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        }
        // else if (checkLS(postCode).toUpperCase() === "LS18") {
        //   let distance = Number(6 - miles);
        //   fee = Number(distance + fee_temp);
        // }
        else if (checkLS(postCode).toUpperCase() === 'LS28') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        }
      } else if (town === 'Rodley') {
        // setFee(2);
        if (checkLS(postCode).toUpperCase() === 'LS12') {
          let distance = Number(3 - miles)
          fee = Number(distance + fee_temp)
        } else if (checkLS(postCode).toUpperCase() === 'LS13') {
          let distance = Number(3 - miles)
          fee = Number(distance + fee_temp)
        }
        //  else if (checkLS(postCode).toUpperCase() === "LS18") {
        //   let distance = Number(3 - miles);
        //   fee = Number(distance + fee_temp);
        // }
        else if (checkLS(postCode).toUpperCase() === 'LS28') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        }
      } else if (town === 'Horstforth') {
        // setFee(2);
        if (checkLS(postCode).toUpperCase() === 'LS12') {
          let distance = Number(6 - miles)
          fee = Number(distance + fee_temp)
        } else if (checkLS(postCode).toUpperCase() === 'LS13') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        }
        // else if (checkLS(postCode).toUpperCase() === "LS18") {
        //   let distance = Number(4 - miles);
        //   fee = Number(distance + fee_temp);
        // }
        else if (checkLS(postCode).toUpperCase() === 'LS28') {
          let distance = Number(3 - miles) // miles < 3 so distance around 3 default
          fee = Number(distance + fee_temp)
        }
      } else if (town === 'Stanningley') {
        // setFee(2);
        if (checkLS(postCode).toUpperCase() === 'LS12') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        } else if (checkLS(postCode).toUpperCase() === 'LS13') {
          let distance = Number(3 - miles)
          fee = Number(distance + fee_temp)
        }
        // else if (checkLS(postCode).toUpperCase() === "LS18") {
        //   let distance = Number(3 - miles);
        //   fee = Number(distance + fee_temp);
        // }
        else if (checkLS(postCode).toUpperCase() === 'LS28') {
          let distance = Number(4 - miles)
          fee = Number(distance + fee_temp)
        }
      } else if (town === 'Pudsey') {
        // setFee(2);
        if (checkLS(postCode).toUpperCase() === 'LS12') {
          let distance = Number(3 - miles)
          fee = Number(distance + fee_temp)
        } else if (checkLS(postCode).toUpperCase() === 'LS13') {
          let distance = Number(3 - miles)
          fee = Number(distance + fee_temp)
        }
        // else if (checkLS(postCode).toUpperCase() === "LS18") {
        //   let distance = Number(3 - miles);
        //   fee = Number(distance + fee_temp);
        // }
        else if (checkLS(postCode).toUpperCase() === 'LS28') {
          let distance = Number(3 - miles)
          fee = Number(distance + fee_temp)
        }
      }
      */
      try {
        setFromCart({
          ...fromCart,
          Fee: fee,
          Total: Number(
            Number(local.state.Subtotal) -
              Number(local.state.Discount).toFixed(2) +
              Number(fee)
          ).toFixed(2),
        })
      } catch {
        history.push("/")
      }
    }
  }, [town, postCode, deliveryChange])

  useEffect(() => {
    try {
      setFromCart({
        cartProducts: local?.state?.cartProducts || [],
        Coupon: local?.state?.Coupon || null,
        Subtotal: local?.state?.Subtotal || 0,
        Fee: local?.state?.Fee || 0,
        Discount: local?.state?.Discount || 0,
        Total: Number(
          Number(local?.state?.Subtotal || 0) -
            Number(local?.state?.Discount || 0).toFixed(2) +
            Number(local?.state?.Fee || 0)
        ).toFixed(2),
      })
      setupPickupState()
    } catch {
      history.push("/")
    }
  }, [])

  useEffect(() => {
    try {
      if (local.state.Coupon == undefined || local.state.Coupon == null) {
      } else {
        Coupons.push(local.state.Coupon)
        setCouponType(true)
      }
    } catch {
      history.push("/")
    }
  }, [Coupons])

  // getting current user function
  function GetCurrentUser() {
    const [user, setUser] = useState(null)

    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          fs.collection("users")
            .doc(user.uid)
            .get()
            .then((snapshot) => {
              setUser(snapshot.data().FirstName)
            })
        } else {
          setUser("")
        }
      })
    }, [])
    return user
  }

  useEffect(() => {
    setTownTemp(town)
    setPostCodeTemp(postCode)
  }, [buttonDisable])

  const handleModalPaypal = () => {
    if (town === "") {
      setModalShow(false)
      return setError("Please select town")
    } else if (postCode === "") {
      setModalShow(false)
      return setError("Please select postcode")
    }
    setModalShow(true)
  }

  function handleChangeState(event) {
    if (event.target.value == "pickup") {
      setButtonDisable(false)
      setPickupState(true)
      localStorage.setItem("Delivery", false)
      localStorage.setItem("Pickup", true)
      setTownTemp(town)
      setPostCodeTemp(postCode)
      setFromCart({
        ...fromCart,
        Fee: 0,
        Total: Number(
          Number(local.state.Subtotal) - Number(local.state.Discount).toFixed(2)
        ).toFixed(2),
      })
    } else if (event.target.value == "delivery") {
      setDeliveryChange(!deliveryChange)
      setPickupState(false)
      setTownTemp(town)
      setPostCodeTemp(postCode)
      localStorage.setItem("Delivery", true)
      localStorage.setItem("Pickup", false)
      setFromCart({
        ...fromCart,
        Fee: fee,
        Total: Number(
          Number(local.state.Subtotal) -
            Number(local.state.Discount).toFixed(2) +
            Number(fee)
        ).toFixed(2),
      })
    }
  }
  function handleChangeTown(e) {
    setDeliveryChange(!deliveryChange)
    // console.log(fee);
    setTown(e.target.value)
    fs.collection("users").doc(uid).update({
      Town: e.target.value,
    })
  }
  function handleChangePostCode(e) {
    setDeliveryChange(!deliveryChange)
    setPostCode(e.target.value)
    fs.collection("users").doc(uid).update({
      PostCode: e.target.value,
    })
  }
  function GetUserUid() {
    const [uid, setUid] = useState(null)
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUid(user.uid)
        }
      })
    }, [])
    return uid
  }
  const user = GetCurrentUser()
  const uid = GetUserUid()

  const [totalProducts, setTotalProducts] = useState(0)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("Cart " + user.uid)
          .get()
          .then((getSnap) => {
            const qty = getSnap.docs.length
            setTotalProducts(qty)
          })
      }
    })
  }, [])

  const handleSubmit = async (type, detailp) => {
    try {
      const delState = localStorage.getItem("Delivery") === "true"

      if (town === "") {
        setError("Please select town")
        return setButtonDisable(false)
      } else if (postCode === "") {
        setError("Please select postcode")
        return setButtonDisable(false)
      }
      if (delState && houseRef.current.value === "") {
        setError("House number shouldn't be empty")
        return setButtonDisable(false)
      }

      let pOrder =
        type === "paypal"
          ? { type: "paypal", detail: detailp }
          : { type: "cash" }

      let houseTemp = delState ? houseRef.current.value : house
      let addressTemp = delState ? addressRef.current.value : address
      let townTemp = town
      let postCodeTemp = postCode

      setMessage("")
      setError("")

      // Fetch order history count
      const orderHistorySnapshot = await fs.collection("orderHistory").get()
      let total = orderHistorySnapshot.docs.length

      // Fetch live orders count
      const liveOrderSnapshot = await fs.collection("liveorder").get()
      total += liveOrderSnapshot.docs.length

      let totalOrder = total + 1

      // Add new live order
      await fs.collection("liveorder").add({
        ...fromCart,
        instructionToRes: textInstructionRef.current.value,
        user: `${firstName} ${lastName}`,
        email: email,
        house: houseTemp,
        address: addressTemp,
        town: townTemp,
        postCode: postCodeTemp,
        Telephone: tel,
        pickupState: pickupState,
        deliveryState: !pickupState,
        date: String(format(new Date(), "LLLL dd, yyyy kk:mm:ss")),
        payment: pOrder,
        orderNo: totalOrder,
        uiduser: uidUser,
      })

      // Update user coupons
      await fs.collection("users").doc(uid).update({
        Coupons: Coupons,
      })

      // Clear user's cart
      const cartSnapshot = await fs.collection("Cart " + uid).get()
      const deletePromises = cartSnapshot.docs.map((doc) =>
        fs
          .collection("Cart " + uid)
          .doc(doc.id)
          .delete()
      )
      await Promise.all(deletePromises)

      window.scrollTo(0, 0)
      setMessage("Order completed... Please wait for order confirmation.")
      setModalShow(false)
      setTimeout(() => {
        history.push({
          pathname: "/ordersuccess",
          state: {
            ...fromCart,
            instructionToRes: textInstructionRef.current.value,
            user: `${firstName} ${lastName}`,
            email: email,
            house: houseTemp,
            address: addressTemp,
            town: townTemp,
            postCode: postCodeTemp,
            Telephone: tel,
            pickupState: pickupState,
            deliveryState: !pickupState,
            date: String(format(new Date(), "LLLL dd, yyyy kk:mm:ss")),
            payment: pOrder,
            orderNo: totalOrder,
          },
        })
      }, 3000)
    } catch (error) {
      console.error("Error during order submission:", error)
      setError("Failed to complete the order. Please try again.")
      setButtonDisable(false)
    }
  }

  if (townTemp !== "" || postCodeTemp !== "") {
    return (
      <>
        <Navbar1 user={user} totalProducts={totalProducts} isAdmin={isAdmin} />
        {message ? <Alert variant="success">{message}</Alert> : ""}
        {error ? <Alert variant="danger">{error}</Alert> : ""}
        {fromCart ? (
          <div className="checkout-container">
            <div className="checkout-form">
              <h1>Checkout</h1>
              <h4
                style={{
                  textAlign: "center",
                  color: "#e80532",
                  fontSize: "22px",
                  marginTop: "14px",
                  marginBottom: "20px",
                  textTransform: "none",
                }}
              >
                You will receive a confirmation call within 5 minutes. <br />
                <br /> If you do not receive confirmation within this time
                frame, <br />
                <br /> please contact the restaurant directly.
              </h4>
              <Form onSubmit={(e) => e.preventDefault()}>
                <FormControl>
                  <FormLabel style={{ color: "#6b6b6b" }}>
                    Collection or Delivery{" "}
                    <span style={{ color: "#e80532", fontWeight: "500" }}>
                      *
                    </span>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    defaultValue={pickupState ? "pickup" : "delivery"}
                    onChange={handleChangeState}
                  >
                    <FormControlLabel
                      value="pickup"
                      control={<Radio style={{ color: "#e80532" }} />}
                      label="Collection"
                    />
                    <FormControlLabel
                      value="delivery"
                      control={<Radio style={{ color: "#e80532" }} />}
                      label="Delivery"
                    />
                  </RadioGroup>
                </FormControl>
                {pickupState ? (
                  <div></div>
                ) : (
                  <div>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#e80532",
                        textTransform: "none",
                      }}
                    >
                      {" "}
                      Delivery only available in LS12, LS13, LS28. If not please
                      call the restaurant 01133-187-268 or change to collection.
                    </span>
                    <br></br>
                    <br></br>
                    <FormLabel>
                      House Number/ Flat Number / House Name{" "}
                      <span style={{ color: "#e80532", fontWeight: "500" }}>
                        *
                      </span>
                    </FormLabel>
                    <Form.Control
                      type="text"
                      ref={houseRef}
                      defaultValue={house}
                      required
                      style={{ marginBottom: "20px" }}
                    />
                    <FormLabel>
                      Address{" "}
                      <span style={{ color: "#e80532", fontWeight: "500" }}>
                        *
                      </span>
                    </FormLabel>
                    <Form.Control
                      type="text"
                      ref={addressRef}
                      defaultValue={address}
                      required
                      style={{ marginBottom: "20px" }}
                    />
                    <FormLabel>
                      Town / City{" "}
                      <span style={{ color: "#e80532", fontWeight: "500" }}>
                        *
                      </span>
                    </FormLabel>
                    <select
                      className="form-control"
                      required
                      onChange={(e) => {
                        handleChangeTown(e)
                      }}
                      defaultValue={townTemp}
                      style={{ marginBottom: "10px" }}
                    >
                      <option value="" disabled={true}>
                        Select Town
                      </option>
                      <option value="Calvery">Calvery</option>
                      <option value="Bramley">Bramley</option>
                      <option value="Armley">Armley</option>
                      <option value="Rodley">Rodley</option>
                      <option value="Horstforth">Horstforth</option>
                      <option value="Stanningley">Stanningley</option>
                      <option value="Pudsey">Pudsey</option>
                    </select>
                    {/* <FormLabel>
                      County{" "}
                      <span style={{ color: "#e80532", fontWeight: "500" }}>
                        *
                      </span>
                    </FormLabel>
                    <Form.Control
                      type="text"
                      ref={countyRef}
                      defaultValue={county}
                      required
                      style={{ marginBottom: "20px" }}
                    /> */}
                    <FormLabel>
                      Post Code{" "}
                      <span style={{ color: "#e80532", fontWeight: "500" }}>
                        *
                      </span>
                    </FormLabel>
                    <Form.Control
                      type="text"
                      ref={postCodeRef}
                      defaultValue={postCode}
                      onChange={(e) => handleChangePostCode(e)}
                      required
                      style={{ marginBottom: "20px" }}
                    />
                    {/* <select
                      className="form-control"
                      required
                      onChange={(e) => {
                        handleChangePostCode(e);
                      }}
                      defaultValue={postCodeTemp}
                      style={{ marginBottom: "10px" }}
                    >
                      <option value="" disabled={true}>
                        Select Postcode
                      </option>
                      <option value="LS12">LS12</option>
                      <option value="LS13">LS13</option>
                      <option value="LS18">LS18</option>
                      <option value="LS28">LS28</option>
                    </select> */}
                  </div>
                )}

                <FormLabel>Instructions to the driver</FormLabel>
                <Form.Control
                  type="text"
                  ref={textInstructionRef}
                  placeholder="Pickup time, allergy."
                  style={{ marginBottom: "20px" }}
                />
              </Form>
            </div>
            <div className="checkout-table">
              <div className="c-cart-summary-box">
                <h5>Your Basket</h5>
                <table>
                  <tbody>
                    {fromCart.cartProducts.map((pro, index) => (
                      <tr key={index}>
                        <td className="c-text">
                          <p
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "0",
                              margin: "0",
                              fontFamily: "Merriweather, serif",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "black",
                            }}
                          >
                            {pro.title} x {pro.qty}
                          </p>
                          {pro.option.map((option, oIndex) => (
                            <p
                              key={oIndex}
                              style={{
                                padding: "0",
                                margin: "0",
                                textIndent: "15px",
                                color: "rgb(130, 130, 130)",
                                fontSize: "14px",
                              }}
                            >
                              {option.menu}
                            </p>
                          ))}
                          {pro.addOn.map((addOn, aIndex) => (
                            <p
                              key={aIndex}
                              style={{
                                padding: "0",
                                margin: "0",
                                textIndent: "15px",
                                color: "rgb(130, 130, 130)",
                                fontSize: "14px",
                              }}
                            >
                              {addOn.menu}
                            </p>
                          ))}
                        </td>
                        <td className="c-price">
                          <span>
                            £{Number(pro.TotalProductPrice).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="c-text">
                        <span>Subtotal</span>
                      </td>
                      <td className="c-price">
                        <span>£{Number(fromCart.Subtotal).toFixed(2)}</span>
                      </td>
                    </tr>
                    {couponType ? (
                      <tr>
                        <td className="c-text">
                          <span>Discount</span>
                        </td>
                        <td className="c-price">
                          <span>-£{Number(fromCart.Discount).toFixed(2)}</span>
                        </td>
                      </tr>
                    ) : null}
                    {fromCart.Fee == 0 ||
                    fromCart.Fee == "0.00" ||
                    fromCart == "0" ? null : (
                      <tr>
                        <td className="c-text">
                          <span>Delivery Fee</span>
                        </td>
                        <td className="c-price">
                          <span>£{Number(fromCart.Fee).toFixed(2)}</span>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className="c-text">
                        <span style={{ fontWeight: 700 }}>Total</span>
                      </td>
                      <td className="c-price">
                        <span style={{ fontWeight: 700 }}>
                          {/* £
                          {Number(
                            Number(fromCart.Subtotal) -
                              Number(fromCart.Discount).toFixed(2) +
                              Number(fromCart.Fee)
                          ).toFixed(2)} */}
                          £{Number(fromCart.Total).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="c-payment">
                  <div className="c-paypal">
                    <Button
                      variant="danger"
                      disabled={buttonDisable}
                      onClick={() => handleModalPaypal()}
                    >
                      Pay with PayPal/Credit Card
                    </Button>
                  </div>
                  <div className="c-cash">
                    <Button
                      variant="danger"
                      disabled={buttonDisable}
                      onClick={() => [
                        setButtonDisable(true),
                        handleSubmit("cash", null),
                      ]}
                    >
                      Pay with Cash
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              backgroundColor: "#fff",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} className="spinner" size="8x" />
          </div>
        )}
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="mb-2 text-center text-red font-weight-bold">
              <span className="text-black font-weight-bold">WARNING:</span>{" "}
              Please do not close the PayPal popup until the payment is
              complete.
            </div>
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
              }}
            >
              <PayPalButtons
                l
                createOrder={(data, actions) => {
                  setButtonDisable(true)
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: Number(fromCart.Total).toFixed(2),
                        },
                      },
                    ],
                  })
                }}
                onApprove={async (data, actions) => {
                  return actions.order.capture().then(async (details) => {
                    // console.log(details.status)
                    if (details.status == "COMPLETED") {
                      await handleSubmit("paypal", details)
                    } else {
                      setError("Payment failed...")
                      setButtonDisable(false)
                      setTimeout(() => {
                        setError("")
                      }, 3000)
                    }
                  })
                }}
                onCancel={(data, actions) => {
                  setError("Payment cancel...")
                  setButtonDisable(false)
                  setTimeout(() => {
                    setError("")
                  }, 3000)
                }}
                onError={(err) => {
                  setError("Payment error...")
                  setButtonDisable(false)
                }}
              />
            </PayPalScriptProvider>
          </Modal.Body>
        </Modal>
      </>
    )
  }
  return null
}

export default Checkout
