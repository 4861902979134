import React, { useState, useRef, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { auth, fs } from "../../Config/Config"
import Header from "./Header"
import Menu from "./Menu"
import Footer from "./Footer"
import { Icon } from "react-icons-kit"
import { plus } from "react-icons-kit/feather/plus"
import { minus } from "react-icons-kit/feather/minus"
import { Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

function FixedAddOn() {
  const history = useHistory()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const [successMsg, setSuccessMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const titleRef = useRef([])
  const [loading, setLoading] = useState(true)
  const [loadingMsg, setLoadingMsg] = useState("")
  const [inputFields, setInputFields] = useState([
    {
      title: "",
      menu: [
        {
          menuName: "",
          price: 0,
        },
      ],
    },
  ])
  useEffect(() => {
    fs.collection("fixedAddOn")
      .doc("option")
      .get()
      .then((snapshot) => {
        setInputFields(snapshot.data().addOn)
        setLoading(false)
      })
      .catch(() => {
        setErrorMsg("No data")
      })
  }, [])

  const handleChangeTitle = (index, event) => {
    const values = [...inputFields]
    values[index]["title"] = event.target.value
    setInputFields(values)
    // console.log(inputFields);
  }

  const handleChangeMenu = (index, index_child, event) => {
    const values = [...inputFields]
    values[index]["menu"][index_child][event.target.name] = event.target.value
    setInputFields(values)
    // console.log(inputFields);
  }

  const handleAddTitle = () => {
    setInputFields([
      ...inputFields,
      {
        title: "",
        menu: [
          {
            menuName: "",
            price: 0,
          },
        ],
      },
    ])
  }

  const handleRemoveTitle = (index) => {
    const values = [...inputFields]
    values.splice(index, 1)
    setInputFields(values)
  }

  const handleAddMenu = (index) => {
    if (inputFields.length == 1) {
      setInputFields([
        {
          title: titleRef.current[index].value,
          menu: [
            ...inputFields[index].menu,
            {
              menuName: "",
              price: 0,
            },
          ],
        },
      ])
    } else {
      const values = [...inputFields]
      values.push({
        title: titleRef.current[index].value,
        menu: [
          ...inputFields[index].menu,
          {
            menuName: "",
            price: 0,
          },
        ],
      })
      values.splice(index, 1)
      setInputFields(values)
    }
  }

  const handleRemoveMenu = (index, index_child) => {
    const values = inputFields[index].menu
    values.splice(index_child, 1)
    if (inputFields.length == 1) {
      setInputFields([
        {
          title: titleRef.current[index].value,
          menu: values,
        },
      ])
    } else {
      const all = [
        ...inputFields,
        {
          title: titleRef.current[index].value,
          menu: values,
        },
      ]
      setInputFields(all)
      all.splice(index, 1)
      setInputFields(all)
    }
  }

  const handleUpdateAddOn = (e) => {
    e.preventDefault()
    setErrorMsg("")
    setLoadingMsg("Loading...")
    //check if each inputFields have title and menuName
    let checkTitle = true
    let checkMenuName = true
    let param = inputFields
    if (inputFields && inputFields.length > 0) {
      inputFields.forEach((inputField) => {
        if (inputField.title === "") {
          checkTitle = false
        }
        if (inputField.menu.length === 0) {
          checkMenuName = false
        } else {
          inputField.menu.forEach((menu) => {
            if (menu.menuName === "") {
              checkMenuName = false
            }
          })
        }
      })
    } else {
      param = [
        {
          title: "",
          menu: [
            {
              menuName: "",
              price: 0,
            },
          ],
        },
      ]
    }
    window.scrollTo(0, 0)
    if (!checkTitle || !checkMenuName) {
      setLoadingMsg("")
      return setErrorMsg("Please fill in the missing Add-on Title/Menu Name")
    }
    fs.collection("fixedAddOn")
      .doc("option")
      .set({
        addOn: param,
      })
      .then(() => {
        setSuccessMsg("Update fixed add-on successfully")
        setLoadingMsg("")
        setTimeout(() => {
          window.location.reload(false)
        }, 2000)
      })
      .catch((error) => {
        setErrorMsg("Failed to update fixed add-on")
      })
  }

  if (loading) {
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div
            style={{
              display: "flex",
              backgroundColor: "#f4f6f9",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} className="spinner" size="8x" />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="container">
          <h2 className="pt-3">Fixed Add-on</h2>
          <hr></hr>
          {loadingMsg ? <Alert variant="secondary">{loadingMsg}</Alert> : ""}
          {successMsg && (
            <>
              <div className="success-msg">{successMsg}</div>
              <br></br>
            </>
          )}
          {errorMsg ? <Alert variant="danger">{errorMsg}</Alert> : ""}
          <form
            autoComplete="off"
            className="form-group"
            onSubmit={handleUpdateAddOn}
          >
            <div className="px-3">
              {inputFields &&
                inputFields.length > 0 &&
                inputFields.map((titleField, index) => (
                  <div key={index}>
                    <label>Add Add-on</label>
                    <div key={index}>
                      <input
                        className="form-control"
                        type="text"
                        name="title"
                        placeholder="Add-on Title"
                        value={titleField.title}
                        ref={(el) => (titleRef.current[index] = el)}
                        onChange={(event) => handleChangeTitle(index, event)}
                      />
                      {titleField.menu.map((menuField, index_child) => (
                        <div key={index_child} className="d-flex">
                          <input
                            className="form-control"
                            type="text"
                            name="menuName"
                            placeholder="Menu Name"
                            value={menuField.menuName}
                            onChange={(event) =>
                              handleChangeMenu(index, index_child, event)
                            }
                            autoFocus
                          />
                          <input
                            className="form-control"
                            type="number"
                            name="price"
                            value={menuField.price}
                            onChange={(event) =>
                              handleChangeMenu(index, index_child, event)
                            }
                          />
                          <Icon
                            icon={plus}
                            size={20}
                            onClick={() => handleAddMenu(index)}
                          />
                          <Icon
                            icon={minus}
                            size={20}
                            onClick={() => handleRemoveMenu(index, index_child)}
                          />
                        </div>
                      ))}
                      <Icon
                        icon={plus}
                        size={20}
                        onClick={() => handleAddTitle()}
                      />
                      <Icon
                        icon={minus}
                        size={20}
                        onClick={() => handleRemoveTitle(index)}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="d-grid justify-content-center mt-3">
              <button type="submit" className="btn btn-success btn-">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FixedAddOn
