import React, { useCallback, useEffect, useState } from "react"
import { fs, auth } from "../../Config/Config"
import { Button, Alert, Modal, Form, Row, Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Header from "./Header"
import Menu from "./Menu"
import Footer from "./Footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import ServerSideDataTable from "./ServerSideDataTable"

function ManageAdmin() {
  const history = useHistory()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const [loadingMsg, setLoadingMsg] = useState("")
  // remove modal
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  // add modal
  const [showAdd, setShowAdd] = useState(false)
  const handleCloseAdd = () => setShowAdd(false)
  const handleShowAdd = () => setShowAdd(true)
  const [uidUser, setUIDUser] = useState("")
  const columns = [
    { field: "id", headerName: "UID", flex: 1, maxWidth: 60 },
    {
      field: "date",
      headerName: "Date",
      maxWidth: 170,
      flex: 1,
      renderCell: (cellValues) => {
        let formattedDate = ""
        let dateString = cellValues.value
        if (typeof dateString === "string" && dateString.trim() !== "") {
          const datePart =
            dateString.split(" ")[0] +
            " " +
            dateString.split(" ")[1] +
            " " +
            dateString.split(" ")[2]
          const timePart = dateString.split(" ")[3]
          const formattedDatePart = moment(datePart, "MMMM D, YYYY").format(
            "DD/MM/YYYY"
          )
          formattedDate = `${formattedDatePart} ${timePart}`
        } else {
          formattedDate = "-"
        }
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        )
      },
    },
    {
      field: "FirstName",
      headerName: "First Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "LastName",
      headerName: "Last Name",
      minWidth: 100,
      flex: 1,
    },
    { field: "Email", headerName: "E-mail", minWidth: 180, flex: 1 },
    {
      field: "isAdmin",
      headerName: "Admin",
      width: 65,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => {
        let cell = cellValues.value ? "Yes" : "-"
        return (
          <div>
            <span
              style={{
                color: cell === "No" ? "black" : "green",
              }}
            >
              {cell}
            </span>
          </div>
        )
      },
    },
    {
      field: "actiob",
      headerName: "Action",
      width: 85,
      headerAlign: "center",
      align: "center",
      sortable: false,

      renderCell: (cellValues) => {
        if (!cellValues.row.isAdmin) {
          return (
            <Button
              variant="success"
              className="btn-sm"
              onClick={() => {
                setUIDUser(cellValues.id)
                handleShowAdd()
              }}
            >
              Add
            </Button>
          )
        } else {
          return (
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => {
                setUIDUser(cellValues.id)
                handleShow()
              }}
            >
              Remove
            </Button>
          )
        }
      },
    },
  ]
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")

  const [search, setSearch] = useState({
    firstName: "",
  })
  const handleSearch = (event) => {
    event.preventDefault()
    const newValue = {
      firstName: event.target[0].value,
    }
    if (search.firstName !== event.target[0].value) {
      setSearch(newValue)
      setPage(0)
    }
  }
  const [triggerReload, setTriggerReload] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const fetchUsers = useCallback(async (page, pageSize, search) => {
    let query = fs.collection("users").orderBy("FirstName", "asc")
    let totalQuery = fs.collection("users").orderBy("FirstName", "asc")

    if (search.firstName !== "") {
      query = query
        .where("FirstName", ">=", search.firstName)
        .where("FirstName", "<=", search.firstName + "\uf8ff")
      totalQuery = totalQuery
        .where("FirstName", ">=", search.firstName)
        .where("FirstName", "<=", search.firstName + "\uf8ff")
    }

    if (page > 0) {
      const lastDocSnapshot = await query.limit(page * pageSize).get()
      const lastDoc = lastDocSnapshot.docs[lastDocSnapshot.docs.length - 1]
      query = query.startAfter(lastDoc)
    }

    const snapshot = await query.limit(pageSize).get()
    const totalDocs = await totalQuery.get()
    const data = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return {
      data,
      total: totalDocs.size,
    }
  }, [])

  async function handleAddButton() {
    try {
      setMessage("")
      setError("")
      setLoading(true)
      setLoadingMsg("Loading...")
      handleCloseAdd()
      window.scrollTo(0, 0)
      fs.collection("users")
        .doc(uidUser)
        .update({
          isAdmin: true,
        })
        .then(() => {
          setMessage("Add Admin Successful")
          setLoadingMsg("")
          setTriggerReload((previosState) => !previosState)
        })
        .catch((error) => {
          setError("Failed to add Admin")
        })
    } catch (error) {
      setError("Failed to add Admin")
    } finally {
      setUIDUser("")
      setTimeout(() => {
        setLoading(false)
        setMessage("")
        setError("")
        setLoadingMsg("")
      }, 3000)
    }
  }

  async function handleRemoveButton() {
    try {
      setMessage("")
      setError("")
      setLoading(true)
      setLoadingMsg("Loading...")
      window.scrollTo(0, 0)
      handleClose()
      fs.collection("users")
        .doc(uidUser)
        .update({
          isAdmin: false,
        })
        .then(() => {
          setMessage("Remove Admin Successful")
          setLoadingMsg("")
          setTriggerReload((previosState) => !previosState)
        })
        .catch(() => {
          setError("Failed to remove Admin")
        })
    } catch {
      setError("Failed to remove Admin")
    } finally {
      setUIDUser("")
      setTimeout(() => {
        setLoading(false)
        setMessage("")
        setError("")
        setLoadingMsg("")
      }, 3000)
    }
  }

  // Note user.key = uid
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="p-3">
          <h2>Admin</h2>
          <div className="search-container">
            <Form onSubmit={handleSearch} className="mb-2">
              <Row className="gy-2 gx-3">
                <Col xs={12} sm={6}>
                  <div className="d-flex align-items-center">
                    <Form.Group className="w-100">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        size="sm" // Smaller size
                        className="form-control-sm"
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="ml-2 btn-sm "
                    >
                      Search
                    </Button>
                  </div>
                  <span className="text-hint pb-0 mb-0">
                    Matches from start, case-sensitive
                  </span>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            <div
              style={{
                backgroundColor: "#FFFF",
              }}
            >
              {loadingMsg ? (
                <Alert variant="secondary">{loadingMsg}</Alert>
              ) : (
                ""
              )}
              {message ? <Alert variant="success">{message}</Alert> : ""}
              {error ? <Alert variant="danger">{error}</Alert> : ""}
              <ServerSideDataTable
                columns={columns}
                fetchData={fetchUsers}
                search={search}
                setPage={setPage}
                setPageSize={setPageSize}
                page={page}
                pageSize={pageSize}
                triggerReload={triggerReload}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to remove this admin?</p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 gap-2">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRemoveButton}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showAdd} onHide={handleCloseAdd}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to add this admin?</p>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 gap-2">
          <Button variant="secondary" onClick={handleCloseAdd}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleAddButton}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  )
}

export default ManageAdmin
