import React, { useState, useEffect, useCallback } from "react"
import { DataGrid } from "@mui/x-data-grid"
const ServerSideDataTable = ({
  columns,
  fetchData,
  search,
  setPage,
  setPageSize,
  page,
  pageSize,
  triggerReload,
}) => {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [rowCount, setRowCount] = useState(0)

  const loadData = useCallback(async () => {
    // console.log("loadData", page, pageSize, search)
    setLoading(true)
    try {
      const { data, total } = await fetchData(page, pageSize, search)
      setRows(data)
      setRowCount(total)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [page, pageSize, search, triggerReload])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <div style={{ height: 511 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20]}
        getRowId={(row) => row.id}
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
        disableColumnMenu
        rowHeight={40}
        sx={{
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
            { fontFamily: "Rubik", padding: 0, margin: 0 },
        }}
        components={{
          NoRowsOverlay: () => (
            <div className="d-flex h-100 justify-content-center align-items-center">
              No data.
            </div>
          ),
          NoResultsOverlay: () => (
            <div className="d-flex h-100 justify-content-center align-items-center">
              No results found.
            </div>
          ),
        }}
      />
    </div>
  )
}

export default ServerSideDataTable
