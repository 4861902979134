import React, { useState, useEffect, useCallback, useRef } from "react"
import Products from "./Products"
import { auth, fs } from "../Config/Config"
import IndividualFilteredProduct from "./IndividualFilteredProduct"
import { Button, Modal } from "react-bootstrap"
import { Icon } from "react-icons-kit"
import { plus } from "react-icons-kit/feather/plus"
import { ic_delete } from "react-icons-kit/md/ic_delete"
import { minus } from "react-icons-kit/feather/minus"
import { useHistory } from "react-router-dom"
import Navbar1 from "./Navbar1"
import { useMediaQuery } from "react-responsive"
import { Scrollbars } from "react-custom-scrollbars-2"
import { shoppingBag } from "react-icons-kit/fa/shoppingBag"
import { Fab } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import allergy_page1 from "../Images/allergy_page1.jpg"

export default function Home(props) {
  const [show, setShow] = useState(false)
  const [showPage, setShowPage] = useState(true)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const yourBasketQuery = useMediaQuery({ query: "(min-width: 860px)" })

  const [isAdmin, setIsAdmin] = useState(false)
  const history = useHistory()
  const handleCheckout = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        history.push("/order")
      } else {
        history.push("/login")
      }
    })
  }

  // getting current user
  const [user, setUser] = useState(null)
  const [uid, setUid] = useState(null)
  // cart
  const [cartProducts, setCartProducts] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  useEffect(() => {
    getProducts()
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Fetch user data
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then(async (snapshot) => {
            if (snapshot.data() == undefined) {
              await auth.signOut()
              localStorage.clear()
              history.push("/login")
            } else {
              setUid(user.uid)
              setUser(snapshot.data().FirstName)
              setIsAdmin(snapshot.data().isAdmin)
              // Listen to cart changes
              const unsubscribeFromCart = fs
                .collection("Cart " + user.uid)
                .onSnapshot((snapshot) => {
                  const newCartProduct = snapshot.docs.map((doc) => ({
                    DOC_ID: doc.id,
                    ...doc.data(),
                  }))
                  const qty = snapshot.docs.length
                  setTotalProducts(qty)
                  setCartProducts(newCartProduct)
                })
              return () => unsubscribeFromCart()
            }
          })
      } else {
        setUser("")
        setUid(null)
        setCartProducts([])
      }
    })

    // Cleanup the auth listener
    return () => {
      unsubscribe()
    }
  }, [history])

  // state of products
  const [products, setProducts] = useState([])

  // getting products function
  const getProducts = async () => {
    const products = await fs.collection("Products").get()
    const productsArray = []
    for (var snap of products.docs) {
      var data = snap.data()
      data.ID = snap.id
      productsArray.push({
        ...data,
      })
      if (productsArray.length === products.docs.length) {
        setProducts(
          productsArray.sort((a, b) => a.title.localeCompare(b.title)),
        )
      }
    }
  }

  // add to cart
  const addToCart = (product) => {
    auth.onAuthStateChanged((user) => {
      if (user && uid !== null) {
        let totalProductPrice = 0
        if (product.option) {
          if (product.option.length > 0) {
            for (let i = 0; i < product.option.length; i++) {
              totalProductPrice += Number(product.option[i].price)
              // console.log(product.option[i].menu, product.option[i].price);
            }
          }
        }
        if (product.addOn) {
          if (product.addOn.length > 0) {
            for (let j = 0; j < product.addOn.length; j++) {
              totalProductPrice += Number(product.addOn[j].price)
              // console.log(product.addOn[j].menu, product.addOn[j].price);
            }
          }
        }
        // console.log("==============");
        let Product
        Product = product
        Product["qty"] = product.qty
        Product["priceWithAddon"] = Product.price + totalProductPrice
        Product["TotalProductPrice"] =
          Product.qty * (Product.price + totalProductPrice)
        fs.collection("Cart " + uid).add(Product)
      } else {
        history.push("/login")
      }
    })
  }

  const [spans, setSpan] = useState([])
  function GetCategoryFromFirebase() {
    const getCategoryFromFirebase = []
    const [categoryFs, setCategoryFs] = useState()
    useEffect(async () => {
      const snapshot = await fs.collection("category").get()
      snapshot.docs.map((doc) => {
        getCategoryFromFirebase.push({ ...doc.data(), key: doc.id })
      })
      setCategoryFs(getCategoryFromFirebase)
    }, [])
    return categoryFs
  }
  const categoryFs = GetCategoryFromFirebase()
  useEffect(() => {
    if (categoryFs == undefined) {
      setSpan(categoryFs)
    } else {
      let sortCategory = categoryFs.sort((a, b) => a.order - b.order)
      setSpan(sortCategory)
    }
  }, [categoryFs])

  // active class state
  const [active, setActive] = useState("")

  // category state
  const [category, setCategory] = useState("")

  // handle change ... it will set category and active states
  const handleChange = (individualSpan) => {
    setShowPage(false)
    setActive(individualSpan.id)
    setCategory(individualSpan.category)
    filterFunction(individualSpan.key)
  }

  // filtered products state
  const [filteredProducts, setFilteredProducts] = useState([])

  // filter function
  const filterFunction = (catUID) => {
    if (products.length >= 1) {
      const filter = products.filter((product) => product.categoryID === catUID)
      setFilteredProducts(filter)
    } else {
      // console.log("no products to filter");
    }
  }

  // return to all products
  const returntoAllProducts = () => {
    setActive("")
    setCategory("")
    setFilteredProducts([])
  }

  // const fee = MilesCal()
  // getting the TotalProductPrice from cartProducts in a seperate array
  const price = cartProducts.map((cartProduct) => {
    return cartProduct.TotalProductPrice
  })

  // reducing the price in a single value
  const reducerOfPrice = (accumulator, currentValue) =>
    accumulator + currentValue

  const subtotalPrice = price.reduce(reducerOfPrice, 0)

  // cart product increase function
  const cartProductIncrease = (cartProduct) => {
    // console.log(cartProduct);
    let Product
    Product = cartProduct
    Product.qty = Product.qty + 1
    Product.TotalProductPrice = Product.qty * Product.priceWithAddon
    // updating in database
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("Cart " + user.uid)
          .doc(cartProduct.DOC_ID)
          .update(Product)
      } else {
        history.push("/login")
      }
    })
  }

  // cart product decrease functionality
  const cartProductDecrease = (cartProduct) => {
    let Product = cartProduct
    if (Product.qty > 1) {
      Product.qty = Product.qty - 1
      Product.TotalProductPrice = Product.qty * Product.priceWithAddon
      auth.onAuthStateChanged((user) => {
        if (user) {
          fs.collection("Cart " + user.uid)
            .doc(cartProduct.DOC_ID)
            .update(Product)
        } else {
          history.push("/login")
        }
      })
    } else if (Product.qty == 1) {
      handleCartProductDelete(cartProduct)
    }
  }

  const handleCartProductDelete = (cartProduct) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("Cart " + user.uid)
          .doc(cartProduct.DOC_ID)
          .delete()
      } else {
        history.push("/login")
      }
    })
  }

  const handleClickIncrease = useCallback((e) => cartProductIncrease(e))
  const handleClickDecrease = useCallback((e) => cartProductDecrease(e))
  const handleClickDelete = useCallback((e) => handleCartProductDelete(e))

  const scrollbarsRef = useRef(null)
  const contentRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleContentDrag)
      document.addEventListener("mouseup", handleDragEnd)
    } else {
      document.removeEventListener("mousemove", handleContentDrag)
      document.removeEventListener("mouseup", handleDragEnd)
    }

    return () => {
      document.removeEventListener("mousemove", handleContentDrag)
      document.removeEventListener("mouseup", handleDragEnd)
    }
  }, [isDragging])

  const handleDragStart = (event) => {
    setIsDragging(true)
    setStartX(event.clientX)
    setScrollLeft(scrollbarsRef.current.view.scrollLeft)
  }
  const handleContentDrag = (event) => {
    const deltaX = event.clientX - startX
    scrollbarsRef.current.view.scrollLeft = scrollLeft - deltaX
  }
  const handleDragEnd = () => {
    setIsDragging(false)
  }

  return (
    <>
      <Navbar1 user={user} totalProducts={totalProducts} isAdmin={isAdmin} />
      {!yourBasketQuery ? (
        <>
          <Fab
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(1.5),
              right: (theme) => theme.spacing(1.5),
              backgroundColor: "#fff",
              height: 47,
              width: 47,
            }}
            className="yourbasket-floatbtn"
            onClick={handleShow}
          >
            <Icon className="basket-icon" icon={shoppingBag} size={30} />
          </Fab>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>YOUR BASKET</Modal.Title>
              <Icon className="your-basket1" icon={shoppingBag} size={20} />
            </Modal.Header>
            <Modal.Body>
              <div className="rightside1">
                <div className="cart-summary-box">
                  <Scrollbars autoHeight autoHeightMax={"40vh"}>
                    <table className="m-auto w-100">
                      <tbody>
                        {Array.isArray(cartProducts) && cartProducts.length ? (
                          cartProducts.map((cartProduct) => {
                            return (
                              <tr key={cartProduct.id}>
                                <td className="">
                                  <div className="action-btns-pointer d-flex align-items-center justify-content-center">
                                    <Icon
                                      icon={minus}
                                      size={20}
                                      onClick={(e) =>
                                        handleClickDecrease(cartProduct)
                                      }
                                      className="px-1"
                                    />
                                  </div>
                                </td>
                                <td>{cartProduct.qty}</td>
                                <td className="">
                                  <div className="action-btns-pointer d-flex align-items-center justify-content-center">
                                    <Icon
                                      icon={plus}
                                      size={20}
                                      onClick={(e) =>
                                        handleClickIncrease(cartProduct)
                                      }
                                      className="px-1"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <p
                                    style={{
                                      padding: "0",
                                      margin: "0",
                                      fontFamily: "Merriweather, serif",
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      color: "black",
                                      maxWidth: "250px",
                                    }}
                                  >
                                    {cartProduct.title}
                                  </p>
                                  {cartProduct.option.map((option, index) => (
                                    <p
                                      key={index}
                                      style={{
                                        padding: "0",
                                        margin: "0",
                                        textIndent: "15px",
                                        color: "rgb(130, 130, 130)",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {option.menu}
                                    </p>
                                  ))}
                                  {cartProduct.addOn.map((addOn, index) => (
                                    <p
                                      key={index}
                                      style={{
                                        padding: "0",
                                        margin: "0",
                                        textIndent: "15px",
                                        color: "rgb(130, 130, 130)",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {addOn.menu}
                                    </p>
                                  ))}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  £
                                  {Number(
                                    cartProduct.priceWithAddon *
                                      cartProduct.qty,
                                  ).toFixed(2)}
                                </td>
                                <td>
                                  <div className="action-btns-pointer d-flex align-items-center justify-content-center">
                                    <Icon
                                      icon={ic_delete}
                                      size={20}
                                      style={{ color: "#c2052a" }}
                                      onClick={(e) =>
                                        handleClickDelete(cartProduct)
                                      }
                                      className="px-1"
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <div className="empty-basket">
                            <span>Basket is empty.</span>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </Scrollbars>

                  <div className="total-section">
                    {/* <div>
                      <span>Subtotal:</span>
                      <span>£{Number(subtotalPrice).toFixed(2)}</span>
                    </div> */}
                    {/* {localStorage.getItem("Delivery") == "true" &&
                    Array.isArray(cartProducts) &&
                    cartProducts.length ? (
                      <>
                        <div>
                          <span>Delivery Fee:</span>
                          <span>£{Number(fee).toFixed(2)}</span>
                        </div>
                        <span className="del-info">
                          {town}&nbsp;{postCode}
                        </span>
                      </>
                    ) : null} */}
                    <div>
                      <span className="t-total">Subtotal:</span>
                      <span className="t-total">
                        £{Number(subtotalPrice).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="arrowbtn" style={{ margin: "4px auto" }}>
                    <Button
                      style={{
                        backgroundColor: "#e80532",
                        borderColor: "#e80532",
                        width: "max-content",
                      }}
                      onClick={handleCheckout}
                    >
                      My Order
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
      <Scrollbars
        style={{
          height: 70,
          width: "100%",
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          borderRadius: "5px",
          marginTop: "0px",
          userSelect: "none",
        }}
        className="shadow"
        onMouseDown={handleDragStart}
        ref={scrollbarsRef}
        // onMouseMove={(e) => handleMove(e)}
        // onMouseUp={(e) => handleUp(e)}
        // autoHide={hideScroll}
        // autoHideTimeout={500}
        renderThumbHorizontal={({ style }) => (
          <div
            style={{
              ...style,
              backgroundColor: "rgb(255, 218, 225)",
              borderRadius: "10px",
            }}
          />
        )}
      >
        <div className="category-container-c" ref={contentRef}>
          <div className="category-tab-c">
            {spans ? (
              <>
                <div className="category-ind-c">
                  <span id={"ALL MENU"} onClick={() => returntoAllProducts()}>
                    ALL MENU
                  </span>
                </div>
                {spans.map((individualSpan, index) => (
                  <div className="category-ind-c" key={index}>
                    <span
                      id={individualSpan.id}
                      onClick={() => handleChange(individualSpan)}
                      className={
                        individualSpan.id === active ? active : "deactive"
                      }
                    >
                      {individualSpan.category}
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Scrollbars>

      <div className="all-home">
        <div className="menu">
          {filteredProducts.length > 0 && (
            <>
              <h1>{category}</h1>
              {/* <div className="a-container">
                <a href="javascript:void(0)" onClick={returntoAllProducts}>
                  RETURN TO ALL MENU
                </a>
              </div> */}
              <div className="menu-container">
                {filteredProducts.map((individualFilteredProduct) => (
                  <IndividualFilteredProduct
                    key={individualFilteredProduct.ID}
                    individualFilteredProduct={individualFilteredProduct}
                    addToCart={addToCart}
                  />
                ))}
              </div>
            </>
          )}
          {filteredProducts.length < 1 && ( //no filter
            <>
              {products.length > 0 &&
                !showPage && ( // find product => all menu
                  <>
                    <h1>ALL MENU</h1>
                    <div className="menu-container">
                      <Products products={products} addToCart={addToCart} />
                    </div>
                  </>
                )}
              {products.length > 0 &&
                showPage && ( // find product => all menu
                  <div className="center-img">
                    <img src={allergy_page1} alt="allergy-logo" />
                  </div>
                )}
              {products.length < 1 && ( // not find product => loading
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#fff",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80vh",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="spinner"
                    size="8x"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ clear: "both" }}></div>

        {!yourBasketQuery ? (
          <></>
        ) : (
          <div className="rightside">
            <div className="cart-summary-box">
              <Icon className="your-basket" icon={shoppingBag} size={34} />
              <Scrollbars autoHeight autoHeightMax={"40vh"}>
                <table>
                  <tbody>
                    {Array.isArray(cartProducts) && cartProducts.length ? (
                      cartProducts.map((cartProduct, cIndex) => {
                        return (
                          <tr key={cIndex}>
                            <td>
                              <div className="action-btns-pointer d-flex align-items-center justify-content-center">
                                <Icon
                                  icon={minus}
                                  size={20}
                                  onClick={(e) =>
                                    handleClickDecrease(cartProduct)
                                  }
                                  className="px-1"
                                />
                              </div>
                            </td>
                            <td className="text-center">{cartProduct.qty}</td>
                            <td>
                              <div className="action-btns-pointer d-flex align-items-center justify-content-center">
                                <Icon
                                  icon={plus}
                                  size={20}
                                  onClick={(e) =>
                                    handleClickIncrease(cartProduct)
                                  }
                                  className="px-1"
                                />
                              </div>
                            </td>
                            <td className="py-1">
                              <p
                                style={{
                                  padding: "0",
                                  margin: "0",
                                  fontFamily: "Merriweather, serif",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  color: "black",
                                }}
                              >
                                {cartProduct.title}
                              </p>
                              {cartProduct.option.map((option, index) => (
                                <p
                                  key={index}
                                  style={{
                                    padding: "0",
                                    margin: "0",
                                    textIndent: "15px",
                                    color: "rgb(130, 130, 130)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {option.menu}
                                </p>
                              ))}
                              {cartProduct.addOn.map((addOn, index) => (
                                <p
                                  key={index}
                                  style={{
                                    padding: "0",
                                    margin: "0",
                                    textIndent: "15px",
                                    color: "rgb(130, 130, 130)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {addOn.menu}
                                </p>
                              ))}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              £
                              {Number(
                                cartProduct.priceWithAddon * cartProduct.qty,
                              ).toFixed(2)}
                            </td>
                            <td>
                              <div className="action-btns-pointer d-flex align-items-center justify-content-center">
                                <Icon
                                  icon={ic_delete}
                                  size={20}
                                  style={{ color: "#c2052a" }}
                                  onClick={(e) =>
                                    handleClickDelete(cartProduct)
                                  }
                                  className="px-1"
                                />
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="empty-basket">
                            <span>Basket is empty.</span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Scrollbars>

              <div className="total-section">
                {/* <div>
                  <span>Subtotal:</span>
                  <span>£{Number(subtotalPrice).toFixed(2)}</span>
                </div> */}
                {/* {localStorage.getItem("Delivery") == "true" ? (
                  <>
                    <div>
                      <span>Delivery Fee:</span>
                      <span>£{Number(fee).toFixed(2)}</span>
                    </div>
                    <span className="del-info">
                      {town}&nbsp;{postCode}
                    </span>
                  </>
                ) : null} */}
                <div>
                  <span className="t-total">Subtotal:</span>
                  <span className="t-total">
                    £{Number(subtotalPrice).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="arrowbtn" style={{ margin: "4px auto" }}>
                <Button
                  style={{
                    backgroundColor: "#e80532",
                    borderColor: "#e80532",
                    width: "max-content",
                  }}
                  onClick={handleCheckout}
                >
                  My Order
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
